import '../scss/app.scss';

// Need jQuery? Install it with "yarn add jquery", then uncomment to require it.
const $ = require('jquery');
// create global $ and jQuery variables
global.$ = global.jQuery = $;

require('bootstrap');
// or you can include specific pieces
// require('bootstrap/js/dist/tooltip');
// require('bootstrap/js/dist/popover');

$(document).ready(function() {
    $('[data-toggle="popover"]').popover();
});

//blueipm-gallery
//The blueimp Gallery jQuery plugin registers a global click handler to open links with data-gallery attribute in the Gallery lightbox.
var blueimp = {};
blueimp.Gallery = require('blueimp-gallery/js/blueimp-gallery');
require('blueimp-gallery/js/jquery.blueimp-gallery.min');
global.blueimp = blueimp;